const multiDefault = () => {
  const elements = document.querySelectorAll('.shopping-form__input--select');
  elements.forEach(el => {
    const choices = new Choices(el, {
      // searchEnabled: false,
      noResultsText: 'Ничего не найдено'
    });
  });

}

multiDefault();