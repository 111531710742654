import Swiper from 'swiper/bundle';


const slider1 = document.querySelector('.slider1');

let mySwiper1 = new Swiper(slider1, {
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    2300: {
      slidesPerView: 5,
    },
    1920: {
      slidesPerView: 4,
    },
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});

const slider2 = document.querySelector('.gift-slider');

let mySwiper2 = new Swiper(slider2, {
  slidesPerView: 1,
  spaceBetween: 25,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    2300: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 4,
    },
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});

const slider3 = document.querySelector('.slider3');

let mySwiper3 = new Swiper(slider3, {
  slidesPerView: 1,
  spaceBetween: 25,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    2300: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 4,
    },
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});

const slider4 = document.querySelector('.feedback-slider');

let mySwiper4 = new Swiper(slider4, {
  slidesPerView: 1,
  spaceBetween: 90,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});

const slider5 = document.querySelector('.gallery-slider');

let mySwiper5 = new Swiper(slider5, {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  zoom: {
    maxRatio: 5,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});

const slider6 = document.querySelector('.exclusives-slider');

let mySwiper6 = new Swiper(slider6, {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    996: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 2,
    }
  }
});
const slider8 = document.querySelector('.slider-small');

let mySwiper8 = new Swiper(slider8, {
  slidesPerView: 3,
  spaceBetween: 10,
  loop: false,
  grid: {
    rows: 2,
    fill: "column",
  },
});

const slider7 = document.querySelector('.slider-big');

let mySwiper7 = new Swiper(slider7, {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  thumbs: {
    swiper: mySwiper8
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

