$(document).ready(function () {
  $('.details-card__btn--minus').click(function () {
    var $input = $(this).parent().find('.details-card__input');
    var count = parseInt($input.val()) - 1;
    count = count < 1 ? 1 : count;
    $input.val(count);
    $input.change();
    return false;
  });
  $('.details-card__btn--plus').click(function () {
    var $input = $(this).parent().find('.details-card__input');
    $input.val(parseInt($input.val()) + 1);
    $input.change();
    return false;
  });
});

const modalButtons = document.querySelectorAll('[data-graph-path="quiz-modal"]');
if (modalButtons) {
  modalButtons.forEach(el => {
    el.addEventListener('click', () => {
      $('.quiz-modal__btn--minus').click(function () {
        var $input = $(this).parent().find('.quiz-modal__input--counter');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
      });
      $('.quiz-modal__btn--plus').click(function () {
        var $input = $(this).parent().find('.quiz-modal__input--counter');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
      });
    });
  });
}

const modalInput = document.querySelector('.quiz-modal__input');
if (modalInput) {
  const modalValue = document.querySelector('.quiz-modal__from');
  modalInput.addEventListener('input', () => {
    modalValue.textContent = modalInput.value.length;
    // console.log(modalInput.value);
  });
}
