// inputmask
const modalForm = document.querySelector('.form');
if (modalForm) {
  const modalSelector = modalForm.querySelector('input[type="tel"]');
  const modalInputMask = new Inputmask('+1 (999) 999-99-99');
  modalInputMask.mask(modalSelector);
}




const form = document.querySelector('.shopping-form__form');
if (form) {
  const telSelector = form.querySelectorAll('input[type="tel"]');
  const inputMask = new Inputmask('+1 (999) 999-99-99');
  telSelector.forEach(el => {
    inputMask.mask(el);
  });
}




// const validation = new JustValidate('.form');

// validation
//   .addField('.input-name', [
//     {
//       rule: 'minLength',
//       value: 3,
//     },
//     {
//       rule: 'maxLength',
//       value: 30,
//     },
//     {
//       rule: 'required',
//       value: true,
//       errorMessage: 'Введите имя!'
//     }
//   ])
//   .addField('.input-mail', [
//     {
//       rule: 'required',
//       value: true,
//       errorMessage: 'Email обязателен',
//     },
//     {
//       rule: 'email',
//       value: true,
//       errorMessage: 'Введите корректный Email',
//     },
//   ])
//   .addField('.input-tel', [
//     {
//       rule: 'required',
//       value: true,
//       errorMessage: 'Телефон обязателен',
//     },
//     {
//       rule: 'function',
//       validator: function () {
//         const phone = telSelector.inputmask.unmaskedvalue();
//         return phone.length === 10;
//       },
//       errorMessage: 'Введите корректный телефон',
//     },
//   ]).onSuccess((event) => {
//     console.log('Validation passes and form submitted', event);

//     let formData = new FormData(event.target);

//     console.log(...formData);

//     let xhr = new XMLHttpRequest();

//     xhr.onreadystatechange = function () {
//       if (xhr.readyState === 4) {
//         if (xhr.status === 200) {
//           console.log('Отправлено');
//         }
//       }
//     }

//     xhr.open('POST', 'mail.php', true);
//     xhr.send(formData);

//     event.target.reset();
//   });